import React from 'react'

import Meta from '../components/meta'
import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'

const BaseLayout = ({ mainWrapperClassName, children }) => {
	const mainWrapperClass = "main-wrapper" + (mainWrapperClassName ? " " + mainWrapperClassName : "")
	
	return (
		<Layout>
			<Meta />
			<div className="main">
				<Header className="header-wrapper" />
				<div className={mainWrapperClass}>
					{ children }
				</div>
				<Footer className="footer-wrapper" />
			</div>
		</Layout>
		)
	}

export default BaseLayout
