import React from 'react'
import { Link } from 'gatsby'

import config from '../../config/site-config'

const Header = ({ className }) => (
	<div className={className} key="header">
		<div className="header-container">
			<div className="header-name--wrapper">
				<Link to="/" className="header-name--link">{config.siteTitle}</Link>
			</div>

			<nav className="header-nav">
				<ul className="header-nav--list">
				{config.featuredPages.map(page => (
					<li className="header-nav--list-item" key={page.label}>
						<Link to={page.url} className="header-nav--link">
							{page.label}
						</Link>
					</li>
				))}
				</ul>
			</nav>
		</div>
	</div>
)
  
export default Header
