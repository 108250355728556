import React from 'react'
import Helmet from 'react-helmet'

const Layout = ({ children }) => (
  <div>
    <Helmet>
      <html data-style="main" />
    </Helmet>
    { children }
  </div>
)

export default Layout
